<template>
    <CRow class="justify-content-center">
        <CModal title="Aviso!" color="danger" :show.sync="showAlert">
            {{ msg }}
            <template #footer>
                <CButton @click="showAlert = false" color="danger">OK</CButton>
            </template>
        </CModal>
        <CCol sm="12">
            <CCard>
                <CCardHeader>
                    <CRow>
                        <CCol sm="12">
                            <h4 id="traffic" class="card-title mb-0">Conta Bancária</h4>
                            <div class="small text-muted">Cadastro de conta de mesma titularidade da empresa</div>
                        </CCol>
                    </CRow>
                </CCardHeader>
                <CCardBody>
                    <CRow class="align-items-center">
                        <CCol  sm="12" class="mb-3 mb-xl-3">
                            <CRow>
                                <CCol sm="6" md="4" v-if="listMode" >
                                    <CButton color="primary" @click="listMode=false;">Incluir</CButton>
                                </CCol>
                                <CCol sm="6" md="4" v-if="listMode===false">
                                    <CButton color="danger" @click="newData">Voltar</CButton>
                                    &nbsp;
                                    <CButton color="primary" @click="setData">Salvar</CButton>
                                </CCol>                               
                            </CRow>
                        </CCol>
                    </CRow>
                    <CRow v-if="listMode">
                        <CCol sm="12">
                            <CDataTable
                                class="mb-0 table-outline"
                                hover
                                head-color="light"
                                :items="accounts"
                                :fields="tableFields"
                                :noItemsView="noItemsView"
                            >
                                <td slot="owner" slot-scope="{item}">
                                    <div v-if="item.person_type === 'CORPORATE'">
                                        <CRow>
                                            <CCol sm="12">
                                                MCQ DESENVOLVIMENTO E LICENCIAMENTO DE PROGRAMAS DE COMPUTADOR EIRELI
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol sm="12">
                                               <b>CNPJ:</b> 26.191.804/0001-09
                                            </CCol>
                                        </CRow>
                                    </div>
                                    <div v-else>
                                        <CRow>
                                            <CCol sm="12">
                                                MARIO CORREIA DE QUEIROZ
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol sm="12">
                                               <b>CPF:</b> 846.622.554-49
                                            </CCol>
                                        </CRow>
                                    </div>
                                </td>
                                <td slot="bank" slot-scope="{item}">
                                    {{item.bank}} - {{getNameBank(item.bank)}}
                                </td>
                                <td slot="branch" slot-scope="{item}">
                                    {{item.branch}}-{{item.branch_verifying_digit}}
                                </td>
                                <td slot="account" slot-scope="{item}">
                                    {{item.account}}-{{item.account_verifying_digit}}
                                </td>
                                <td slot="validated" slot-scope="{item}">                                    
                                    <CBadge v-if="item.validated===true" color="primary" class="mfs-auto">Verificado</CBadge>
                                    <CBadge v-else-if="item.validated===false" color="primary" class="mfs-auto">Conta Inválida</CBadge>
                                    <CBadge v-else color="danger" class="mfs-auto">Não Verificado</CBadge>                                   
                                </td>
                            </CDataTable>
                        </CCol>
                    </CRow>
                    <CRow v-if="listMode===false">
                        <CCol sm="12">
                            <!--
                            <CRow>
                                <CCol sm="12">
                                    <h5 class="mb-3">Selecione o tipo de conta:</h5>
                                </CCol>
                                <CCol sm="12">
                                    <CRow form class="form-group">
                                        <CInputRadioGroup
                                            class="col-sm-12 ml-3"
                                            :options="accountTypePerson"
                                            @update:checked="selectAccountType"
                                        />
                                    </CRow>
                                </CCol>
                            </CRow>-->
                            <CRow v-if="accountTypePersonSelect">
                                <CCol sm="12">
                                    <CRow>
                                        <CCol sm="7">
                                            <CInput 
                                                v-if="accountTypePersonSelect==='PERSON'"
                                                disabled 
                                                label="Nome do Titular" 
                                                value="MARIO CORREIA DE QUEIROZ"
                                            />
                                            <CInput 
                                                v-else
                                                disabled 
                                                label="Razão Social" 
                                                value="MCQ DESENVOLVIMENTO E LICENCIAMENTO DE PROGRAMAS DE COMPUTADOR EIRELI"
                                            />
                                        </CCol>
                                        <CCol sm="5">
                                            <CInput
                                                v-if="accountTypePersonSelect==='PERSON'"
                                                disabled 
                                                label="CPF do Titular" 
                                                value="846.622.554-49" 
                                            />
                                            <CInput
                                                v-else
                                                disabled 
                                                label="CNPJ da Empresa" 
                                                value="26.191.804/0001-09" 
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="6">
                                            <CSelect
                                                label="Banco"
                                                :options="listBancos"
                                                :value.sync="bancoNovaConta"
                                            />
                                        </CCol>
                                        <CCol sm="6">
                                            <CSelect
                                                label="Tipo de Conta"
                                                :options="tpConta"
                                                :value.sync="tpNovaConta"
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="12" md="6">                                 
                                            <CInput
                                                label="Agência Bancária"
                                                v-model="agenciaNovaConta"
                                                v-mask="'##############'"
                                            >
                                                <template #append>
                                                    <CInput
                                                        label="Dígito*"
                                                        v-model="digitoAgenciaNovaConta"
                                                        v-mask="'##'"
                                                        style="margin-top:-29px; max-width: 60px;"
                                                    />
                                                </template>
                                            </CInput>
                                        </CCol>
                                        <CCol sm="12"  md="6">
                                            <CInput
                                                label="Conta"
                                                v-model="numeroNovaConta"
                                                v-mask="'##############'"
                                            >
                                                <template #append>
                                                    <CInput
                                                        label="Dígito*"
                                                        v-model="digitoNumeroNovaConta"
                                                        v-mask="'##'"
                                                        style="margin-top:-29px; max-width: 60px;"
                                                    />
                                                </template>
                                            </CInput>
                                        </CCol>
                                        <CCol sm="2"  md="1" style="padding: 0px; right: 18px">
                                            
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="12">
                                            <div class="small text-muted">* Use 0 para dígito em branco ou letra.</div>
                                        </CCol>
                                    </CRow>
                                </CCol>
                            </CRow>
                        </CCol>                        
                    </CRow>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
import functions from "@/functions";
import BankAccountValidator from "br-bank-account-validator";
const axios = require("axios").default;
const { Bancos } = functions;
export default {
    name: 'ContaBancaria',
    data () {
        return {
            listMode: true,
            showAlert: false,
            msg: '',
            form: {},
            tpConta:[
                {label:'Selecione...',value:''},
                {label:'CC - Conta Corrente',value:'CC'},
                {label:'CP - Conta Poupança', value:'CP'},
                {label:'IP - Instituição Pagamentos', value:'IP'}
            ],
            tpNovaConta:'',
            bancoNovaConta: '',
            numeroNovaConta: '',
            digitoNumeroNovaConta: '',
            agenciaNovaConta: '',
            digitoAgenciaNovaConta: '',
            accountTypePerson:[
                {
                    value:'PERSON',
                    label:'Conta Pessoal',
                },
                {
                    value:'CORPORATE',
                    label:'Conta Júridica',
                }
            ],
            accountTypePersonSelect:'CORPORATE',
            tableFields: [
                { key: 'owner', label: 'Titular' },
                { key: 'bank', label: 'Banco'  },
                { key: 'account_type_destination',  label: 'Tipo' },
                { key: 'branch', label: 'Agência' },
                { key: 'account', label: 'Conta' },
                { key: 'validated', label: 'Status' },
            ],
            accounts:[],
            noItemsView:{ 
                noResults: 'Nenhum resultado para este filtro', 
                noItems: 'Nenhuma conta cadastrada' 
            },
            listBancos: Bancos().map(e=> `${e.value} - ${e.name}`),
        }
    },
    methods: {
        ...functions,
        getData: function () {
            const token = localStorage.getItem('user-token');
            axios({
                method: 'get',
                url: this.vhost('mediator/accountperson'),
                headers: { "Content-Type": "application/json","x-access-token": token},
            })
            .then((res) => {
                this.accounts = res.data.data;
            })
            .catch(this.onError);
        },
        setData: function () {
            const token = localStorage.getItem('user-token');
            const data={
                bank: this.bancoNovaConta.substring(0,3),
                branch: this.agenciaNovaConta,
                account: this.numeroNovaConta,
                branch_verifying_digit: this.digitoAgenciaNovaConta,
                account_verifying_digit: this.digitoNumeroNovaConta,
                account_type_destination: this.tpNovaConta,
                id_company: this.form.id,
                person_type: this.accountTypePersonSelect,
            }
            if (!data.person_type){
                return this.onError('Selecione um Conta Pessoal/Jurídica');
            }
            if (!data.bank || data.bank ==='000'  ){
                return this.onError('Selecione um banco.');
            }
            if (!data.account_type_destination){
                return this.onError('Selecione o tipo de conta.');
            }
            if (!data.branch){
                return this.onError('Digite sua agência.');
            }
            if (data.branch.length < 3){
                return this.onError('Número de agência inválido.');
            }
            if (!data.branch_verifying_digit){
                return this.onError('Digite o dígito verificador da sua agência.');
            }
            if (!data.account){
                return this.onError('Digite sua conta.');
            }
            if (data.account.length < 3){
                return this.onError('Número de conta inválido.');
            }
            if (!data.account_verifying_digit){
                return this.onError('Digite o dígito verificador da sua conta.');
            }
            try {
                BankAccountValidator.validate({
                    bankNumber: data.bank,
                    agencyNumber: data.branch,
                    agencyCheckNumber: data.branch_verifying_digit,
                    accountNumber: data.account,
                    accountCheckNumber: data.account_verifying_digit,
                });
            } catch (e) {
                return this.onError(e.message);
            }
            const config = {
                method: 'post',
                url: this.vhost('mediator/accountperson'),
                headers: { "Content-Type": "application/json","x-access-token": token},
                data
            }      
            axios(config)
                .then(() => {
                    this.newData();
                    this.getData();
                })
                .catch(this.onError);
        },
        newData: function(){
            this.tpNovaConta = '';
            this.bancoNovaConta = '';
            this.numeroNovaConta = '';
            this.digitoNumeroNovaConta = '';
            this.agenciaNovaConta = '';
            this.digitoAgenciaNovaConta = '';
            this.listMode = true;
            this.accountTypePersonSelect = '';
        },
        setAlert: function (message){
            this.msg=message;
            this.showAlert=(message !== undefined);
        },
        getNameBank(codBank){
            return Bancos().find(e=> (codBank==e.value)).name.toString();
        },
        selectAccountType(param){
            this.accountTypePersonSelect=param;
        },
        onError(err){
            if (err.response && err.response.data) {
                if (err.response.data.auth===false) {
                    localStorage.removeItem('user-token');
                    localStorage.removeItem('user-data');
                    this.$router.push('/admin/login');
                } else if (err.response.data.erro){
                    this.setAlert(err.response.data.erro); 
                } else {
                    this.setAlert(err.message);
                }
            } else if(typeof(err)==='string') { 
                this.setAlert(err);
            } else {
                this.setAlert(err.message);
            }
        },
        onResize () {
            this.isMobile = window.innerWidth < 576;
            this.isFullDesktop = window.innerWidth > 1300;
        },
    },
    mounted () {
        this.getData();
        this.onResize();
        window.addEventListener('resize', this.onResize, { passive: true });
    },
}
</script>

<style>

</style>